/* tslint:disable */
/* eslint-disable */
/**
 * Portal API Glommen Mjøsen Skog
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { GetKeyFiguresOutput } from '../model';
// @ts-ignore
import { SubscriptionModel } from '../model';
/**
 * PublicApi - axios parameter creator
 * @export
 */
export const PublicApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a newsletter subscription
         * @param {string} [solution] 
         * @param {SubscriptionModel} [subscriptionModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicCreateNewsletterSubscription: async (solution?: string, subscriptionModel?: SubscriptionModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/public/subscription/newsletter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (solution !== undefined) {
                localVarQueryParameter['solution'] = solution;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscriptionModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Help text for portal - including tooltips etc - editable in CRM  - moved from EPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicGetHelpTexts: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/public/web/help-texts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary File can be found 1 catalog dowPn - eg. Miljø/Instruks for miljø.pdf
         * @param {string} [fileUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicGetInstructionFile: async (fileUrl?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/public/web/instruction-file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fileUrl !== undefined) {
                localVarQueryParameter['fileUrl'] = fileUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Instruction files for portal - editable in CRM  - moved from EPI
         * @param {string} [fileName] 
         * @param {string} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicGetInstructionFiles: async (fileName?: string, category?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/public/web/instruction-files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Instruction text for portal - editable in CRM  - moved from EPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicGetInstructionText: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/public/web/instruction-text`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Key figures for use on public homepage - editable in CRM
         * @param {string} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicGetKeyFigures: async (year: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            assertParamExists('publicGetKeyFigures', 'year', year)
            const localVarPath = `/api/v1/public/measures/{year}`
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Privacy policy for portal - editable in CRM  - moved from EPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicGetPrivacyPolicy: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/public/web/privacy-policy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicApi - functional programming interface
 * @export
 */
export const PublicApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a newsletter subscription
         * @param {string} [solution] 
         * @param {SubscriptionModel} [subscriptionModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicCreateNewsletterSubscription(solution?: string, subscriptionModel?: SubscriptionModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicCreateNewsletterSubscription(solution, subscriptionModel, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PublicApi.publicCreateNewsletterSubscription']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Help text for portal - including tooltips etc - editable in CRM  - moved from EPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicGetHelpTexts(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicGetHelpTexts(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PublicApi.publicGetHelpTexts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary File can be found 1 catalog dowPn - eg. Miljø/Instruks for miljø.pdf
         * @param {string} [fileUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicGetInstructionFile(fileUrl?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicGetInstructionFile(fileUrl, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PublicApi.publicGetInstructionFile']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Instruction files for portal - editable in CRM  - moved from EPI
         * @param {string} [fileName] 
         * @param {string} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicGetInstructionFiles(fileName?: string, category?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicGetInstructionFiles(fileName, category, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PublicApi.publicGetInstructionFiles']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Instruction text for portal - editable in CRM  - moved from EPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicGetInstructionText(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicGetInstructionText(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PublicApi.publicGetInstructionText']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Key figures for use on public homepage - editable in CRM
         * @param {string} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicGetKeyFigures(year: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetKeyFiguresOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicGetKeyFigures(year, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PublicApi.publicGetKeyFigures']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Privacy policy for portal - editable in CRM  - moved from EPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicGetPrivacyPolicy(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicGetPrivacyPolicy(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PublicApi.publicGetPrivacyPolicy']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * PublicApi - factory interface
 * @export
 */
export const PublicApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a newsletter subscription
         * @param {string} [solution] 
         * @param {SubscriptionModel} [subscriptionModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicCreateNewsletterSubscription(solution?: string, subscriptionModel?: SubscriptionModel, options?: any): AxiosPromise<void> {
            return localVarFp.publicCreateNewsletterSubscription(solution, subscriptionModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Help text for portal - including tooltips etc - editable in CRM  - moved from EPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicGetHelpTexts(options?: any): AxiosPromise<string> {
            return localVarFp.publicGetHelpTexts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary File can be found 1 catalog dowPn - eg. Miljø/Instruks for miljø.pdf
         * @param {string} [fileUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicGetInstructionFile(fileUrl?: string, options?: any): AxiosPromise<string> {
            return localVarFp.publicGetInstructionFile(fileUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Instruction files for portal - editable in CRM  - moved from EPI
         * @param {string} [fileName] 
         * @param {string} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicGetInstructionFiles(fileName?: string, category?: string, options?: any): AxiosPromise<string> {
            return localVarFp.publicGetInstructionFiles(fileName, category, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Instruction text for portal - editable in CRM  - moved from EPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicGetInstructionText(options?: any): AxiosPromise<string> {
            return localVarFp.publicGetInstructionText(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Key figures for use on public homepage - editable in CRM
         * @param {string} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicGetKeyFigures(year: string, options?: any): AxiosPromise<GetKeyFiguresOutput> {
            return localVarFp.publicGetKeyFigures(year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Privacy policy for portal - editable in CRM  - moved from EPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicGetPrivacyPolicy(options?: any): AxiosPromise<string> {
            return localVarFp.publicGetPrivacyPolicy(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicApi - object-oriented interface
 * @export
 * @class PublicApi
 * @extends {BaseAPI}
 */
export class PublicApi extends BaseAPI {
    /**
     * 
     * @summary Create a newsletter subscription
     * @param {string} [solution] 
     * @param {SubscriptionModel} [subscriptionModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicCreateNewsletterSubscription(solution?: string, subscriptionModel?: SubscriptionModel, options?: RawAxiosRequestConfig) {
        return PublicApiFp(this.configuration).publicCreateNewsletterSubscription(solution, subscriptionModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Help text for portal - including tooltips etc - editable in CRM  - moved from EPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicGetHelpTexts(options?: RawAxiosRequestConfig) {
        return PublicApiFp(this.configuration).publicGetHelpTexts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary File can be found 1 catalog dowPn - eg. Miljø/Instruks for miljø.pdf
     * @param {string} [fileUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicGetInstructionFile(fileUrl?: string, options?: RawAxiosRequestConfig) {
        return PublicApiFp(this.configuration).publicGetInstructionFile(fileUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Instruction files for portal - editable in CRM  - moved from EPI
     * @param {string} [fileName] 
     * @param {string} [category] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicGetInstructionFiles(fileName?: string, category?: string, options?: RawAxiosRequestConfig) {
        return PublicApiFp(this.configuration).publicGetInstructionFiles(fileName, category, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Instruction text for portal - editable in CRM  - moved from EPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicGetInstructionText(options?: RawAxiosRequestConfig) {
        return PublicApiFp(this.configuration).publicGetInstructionText(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Key figures for use on public homepage - editable in CRM
     * @param {string} year 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicGetKeyFigures(year: string, options?: RawAxiosRequestConfig) {
        return PublicApiFp(this.configuration).publicGetKeyFigures(year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Privacy policy for portal - editable in CRM  - moved from EPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicGetPrivacyPolicy(options?: RawAxiosRequestConfig) {
        return PublicApiFp(this.configuration).publicGetPrivacyPolicy(options).then((request) => request(this.axios, this.basePath));
    }
}

